
import { defineComponent } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatableLeft.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import EntityModal from '@/components/modals/forms/entity/EntityModal.vue';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiMixin';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'pending-map-trainer-course-list',
  components: {
    EntityModal,
    Datatable,
  },
  data() {
    return {
      api_url: '',
      componentkey: 0,
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      actionApprove: false,
      actionEdit: false,
      actionReject: false,
      districts: [],
      subDistricts: [],
      empDistricts: [],
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '150px',
        },

        {
          name: 'Trainer',
          key: 'trainer',
          sortable: true,
        },
        {
          name: 'Course',
          key: 'course',
          sortable: true,
        },
        {
          name: 'Institute',
          key: 'institute',
          sortable: true,
        },
      ],
      tableData: [
        // {
        //   id: 1,
        //   trainer: {
        //     name: "Zohirul Alam Tiemoon",
        //     email: "tiemoon@gmail.com",
        //     phone: "+8801756888319",
        //     gender: "Male",
        //   },
        //   course: {
        //     name: "Welding & Fabrication (NE)",
        //     code: "W&F (NE)",
        //     training_type: "New Entrant",
        //     course_month: "3",
        //     tranche: "TR-1",
        //   },
        // },
        // {
        //   id: 2,
        //   trainer: {
        //     name: "Md. Nahidur Rahman Rifath",
        //     email: "nrifath2009@gmail.com",
        //     phone: "+8801672089753",
        //     gender: "Male",
        //   },
        //   course: {
        //     name: "Electrical & Navigation Equipment Installation (NE)",
        //     code: "E&N (NE)",
        //     training_type: "New Entrant",
        //     course_month: "3",
        //     tranche: "TR-1",
        //   },
        // },
      ] as any,
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    await this.MapTrainerWithCourseList();
    await this.actioncheck();
    this.emitter.on('pendingTrainerCourse', async () => {
      await this.MapTrainerWithCourseList();
    });
  },
  methods: {
    async MapTrainerWithCourseList() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      let mapping_type = '2';
      let active_status = 0;
      await ApiService.get(
        'entity/trainer/map_trainer_info?mapping_type=' +
          mapping_type +
          '&active_status=' +
          active_status +
          '&entity_id=' +
          entity_id
      )
        .then((response) => {
          this.tableData = response.data.data;

          this.loading = false;
          this.componentkey += 1;
        })
        .catch(({ response }) => {
          this.loading = false;
          console.log(response);
        });
    },
    async getEntityInfos() {
      await ApiService.get('entity/infos')
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get('entity/types')
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async actioncheck() {
      let menu = JSON.parse(localStorage.getItem('menu') || '{}');
      if (menu) {
        for (let i = 0; i < menu.length; i++) {
          //console.log(menu[i].action);
          if (menu[i].heading == 'Trainer') {
            //console.log(menu[i].action);
            let actions = menu[i].action;
            console.log(actions);
            for (let j = 0; j < actions.length; j++) {
              if (actions[j].action_name === 'Approve') {
                this.actionApprove = true;
              }
              if (actions[j].action_name === 'Edit') {
                this.actionEdit = true;
              }
              if (actions[j].action_name === 'Reject') {
                this.actionReject = true;
              }
            }
          }
        }
      }
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
    edit(data) {
      this.emitter.emit('edit-modal-data', data);
    },

    add() {
      this.emitter.emit('add-modal-data', true);
    },
    Approve(id) {
      let data = {
        active_status: 1,
      };
      Swal.fire({
        title: 'Are you sure you want to Approve it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Approve!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update('entity/trainer/map-status-change/' + `${id}`, data)
            .then((response) => {
              this.emitter.emit('pendingTrainerCourse', true);
              this.componentkey += 1;
              Swal.fire('Approved!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
    Suspend(id) {
      let data = {
        active_status: 2,
      };
      Swal.fire({
        title: 'Are you sure you want to suspend it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Suspend!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.update('entity/trainer/map-status-change/' + `${id}`, data)
            .then((response) => {
              this.emitter.emit('pendingTrainerCourse', true);
              Swal.fire('Suspended!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
